.cat {
  cursor: pointer;
  width: 180px;
  padding: 0px;
  margin: 25px;
}
.cat-img{
  width: 128px;
  padding: 10px;
  margin: 20px;
}