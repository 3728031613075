/* Coding With Damith M.Jayalath  */
/* Amago Toure */
/* 2022-08-16 */

.wrap-login{
    width: 500px;
    background: #fff;
    border-radius: 50px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 60px 100px 40px 100px;
} 
.login-pic{
    width: 550px;
}
.login-pic img{
    max-width: 100%;
}
.login-form{
    width: 300px;
}
.login-form-title{
    font-family: 'poppins', sans-serif;
    font-size: 24px;
    color: #333333;
    line-height: 1.2;
    text-align: center;
    font-weight: 700;
    width: 100%;
    display: block;
    padding-bottom: 54px;
}
.login-title{
    font-family: sans-serif;
    font-size: 12px;
    color: #000000;
    line-height: 1.2;
   
    font-weight: 100;
    width: 100%;
    display: block;
    padding-bottom: 20px;
}
.login-form-title_1{
    font-family: sans-serif;
    font-size: 12px;
    color: #000000;
    line-height: 1.2;
    text-align: center;
    font-weight: 100;
    width: 100%;
    display: block;
    padding-bottom: 20px;
}
.login-form-title_2{
    font-family: sans-serif;
    font-size: 20px;
    color: #000000;
    line-height: 1.2;
    text-align: center;
    font-weight: 700;
    width: 100%;
    display: block;
    padding-bottom: 20px;
}
.login-form-title_3{
    font-family: sans-serif;
    font-size: 13px;
    color: #000000;
    line-height: 1.2;
    text-align: center;
    font-weight: 400;
    width: 100%;
    display: block;
    padding-bottom:30px;
}
.wrap-input{
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 10px;
}
.input{
    font-family: 'Poppins' , sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
    color: #666666;
    outline: none;
    border: none;
    display: block;
    width: 100%;
    background: #e6e6e6;
    height: 50px;
    border-radius: 25px;
    padding: 0 30px 0 68px;
}
.focus-input{
    display: block;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 0px 0px;
    color: rgba(42,184,234,1);
}
.input:focus + .focus-input{
    animation:  anim-shadow 0.5s ease-in-out forwards;
}
@-webkit-keyframes anim-shadow{
    to {
        box-shadow:  0px 0px 70px 25px ;
        opacity: 0;
    }
}
@keyframes anim-shadow{
    to {
        box-shadow:  0px 0px 70px 25px ;
        opacity: 0;
    }
}

.symbol-input{
    font-size: 15px;
    display: flex;
    align-items: center;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 35px;
    pointer-events: none;
    color: #666666;
    transition: all 0.4s
}
.input:focus + .focus-input + .symbol-input{
    color: #2ab8ea;
    padding-left: 28px;
}

.login-form-btn-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
}
.login-form-btn{
    font-family:'poppins',sans-serif ;
    font-size: 15px;
    line-height: 1.5;
    color: #fff;
    background: #2ab8ea;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px ;
    transition: all 0.4s;
    border: none;

}
.remember-password{
    font-family: Helvetica;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.65px;
    color: #7e7676;
}
.login-form-btn:hover{
    background: #3890af;
}
.text-center{
    text-align: center;
}
.txt1{
    font-family: 'poppins';
    font-size: 13px;
    line-height: 1.5;
    color: #666666;
}
.txt2{
    font-family: 'poppins';
    font-size: 13px;
    line-height: 1.5;
    color: #666666;
}
.p-t-1{
    padding-top: 12px;
}
.p-t-2{
    padding-top: 136px;
}

a{
    font-family: 'poppins', sans-serif;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    transition: all 0.4s;
    text-decoration: none;
    font-weight: 400;
}
a:focus{
    outline: none !important;
}
a:hover{
    color: #2ab8ea;
}
button{
    outline: none !important;
    border: none;
    background: transparent;
}
button:hover{
    cursor: pointer;
}

/* Responsive */
@media (max-width: 992px){

.wrap-login{
    padding: 177px 90px 33px 85px;
}

.login-pic{
    width: 35%;
}
.login-form{
    width: 50%;
}

}


@media (max-width: 768px){
    .wrap-login{
        padding: 100px 80px 33px 80px;
    }
    
    .login-pic{
       display: none;
    }
    .login-form{
        width: 100%;
    } 
    .login-pic{
        width: 50%;
    }
}


@media (max-width: 576px){
    .wrap-login{
        padding: 100px 15px 33px 15px;
    }
}