.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
  }
  
  /* Style the buttons inside the tab */
  .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
  }
  
  /* Change background color of buttons on hover */
  .tab button:hover {
    background-color: #ddd;
  }
  
  /* Create an active/current tablink class */
  .tab button.active {
    background-color: #ccc;
  }
  
  /* Style the tab content */
  .tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
  }
  form.example input[type=text] {
    padding: 5px;
    font-size: 17px;
    border: 1px solid #e4e4e4;
    float: left;
    width: 20%;
    background: #f1f1f1;
  }
  
  form.example button {
    float: lerft;
    width: 5%;
    padding: 5px;
    background: #fcbe00;
    color: white;
    font-size: 17px;
    border-left: none;
    cursor: pointer;
    border: 1px solid #e4e4e4;
  }
  
  form.example button:hover {
    background: #fcbd0096;
  }
  
  form.example::after {
    content: "";
    clear: both;
    display: table;
  }
 
  .pagination_layout{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
  
  }
  .pagination a {
    color: black;
    float: right;
    padding: 0px 16px;
    text-decoration: none;
    transition: background-color .3s;
  }
  
  .pagination a.active {
    background-color:#fcbe00;
    ;
    color: white;
  }
  
  .pagination a:hover:not(.active) {
    background-color: #ddd;
  }
  .checkout-form-list {
    margin-bottom: 0;
  }
  /* Solid border */
hr.solid {
  border-top: 3px solid #bbb;
  margin: 15px;
}
b, strong {
  font-weight: bolder;
  COLOR: #5776f3;
}
label {
  text-align: left;
  clear: both;
  float:left;
  margin-right:15px;
}
 .your-order-table table td {
  border-bottom: 0px solid #e4e4e4;
  border-right: medium none; 
  color: #6f7172;
  font-size: 14px;
  padding: 5px 0px;
  text-align: left;

}
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.your-order-table table {
  background: none;
  border: 0;
  width: 80%;
}

.span-font-css {
  color: #263c97;
  font-weight: bold;
}

.float-right {
  float: right;
}
.checkout-form-list {
  margin-bottom: 30px;
}