#content-container { 
	
	position: relative; 
  width: 320px; 
  height: 150px; 
	/* overflow: hidden;  */
	display: block;  
	margin-right: auto;  
}

#_bg__related_pr_ek2 {
	top: 0px;
	left: 0px;
	/* width: 300px;
	height: 200px; */
	background: #e9ecef;
}
#image {
	top: 0px !important;
	position: absolute !important;
	left: 0px;
	width: 100px;
	height: 100px;
	/* background:url(skins/image.png); */
	background-size:cover;
}
#cross {
  position: relative;
  display: inline-block;
  padding: 0 10px;
  color: rgba(255,0,0,0.65) !important; 
}

#text_1 {
  top: 31px;
  left: 100px;

  height: 21px;
  overflow: hidden;
  font-family: Segoe UI;
  font-size: 13;
  text-align: left;
  color: #000000;
}

#text_2 {
  top: 31px;
  left: 100px;
  
  height: 21px;
  overflow: hidden;
  font-family: Segoe UI;
  font-size: 13;
  text-align: center;
  color: #000000;
}



#text_3 {
	top: 31px;
    left: 100px;
 
    height: 21px;
    overflow: hidden;
    font-family: Segoe UI;
    font-size: 13;
    text-align: center;
    color: #000000;
}



#text_4 {
    top: 31px;
    left: 100px;
   
    height: 21px;
    overflow: hidden;
    font-family: Segoe UI;
    font-size: 13;
    text-align: center;
    color: #000000;
}

.aacart-btn-3 {
    top: 10px;
    left: 132px;
    font-size: 13px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 2px 27px;
    background-color: #16bcdc;
    color: rgb(255, 255, 255);
    display: inline-block;
    border: 1px solid transparent;
    text-transform: uppercase;
    text-align: center;
}

#bt_text_1 {
	color:#ffffff;
}
#bg1 {
	top: 11px;
	right: 94px;
	width: 132px;
	height: 81px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	border: 1px solid #cccaca;
	background:rgba(29,7,7,0);
}
.label {
    color: white;
    padding: 8px;
    font-family: Arial;
  }
.doccumnets {
    background-color: #f55959;
    top: auto;
    font-size: 20px;
    padding: 10px;
    color: white;
    font-style: initial;
}

.choose-handfrees {
    background-color: #2196F3;
    top: auto;
    font-size: 20px;
    padding: 10px;
    color: white;
    font-style: initial;
}
.choose-chagers{
    background-color: #2196F3;
    top: auto;
    font-size: 20px;
    padding: 10px;
    color: white;
    font-style: initial;
}
/* Style the tab */
.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
  }
  
  /* Style the buttons inside the tab */
  .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
  }
  
  /* Change background color of buttons on hover */
  .tab button:hover {
    background-color: #ddd;
  }
  
  /* Create an active/current tablink class */
  .tab button.active {
    background-color: #ccc;
  }
  
  /* Style the tab content */
  .tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
  }
  .table2 {
    border-collapse: separate;
    /* border-spacing: 15px 50px; */
  }

  .sp-image {
    width: 85px;
  }

  .n-sidebar-feed ul li {
    display: flex;
    align-items: flex-start; 
    padding: 10px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.658);

}

.widget {
  padding: 5px 5px;
  border: 2px solid #f3f3f3;
  margin-bottom: 20px;
}

.feed-number img {
    margin-right: 20px;
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.vat {
  color: rgb(255, 4, 4) !important;
  font-size: small !important;
}

.link {
  color: #151515 !important;
}

.keyAttribute {
  display: flex;
  flex-direction: row;
}

.keyAttContainer {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
}

.keyname {
  width: 50%;
}

.keyval {
  width: 50%;
}

.variantOpLable {
  padding: 10px 10px 10px 0px;
  width: 30%;
}

.variantOpLableSpan {
  float: right;
}

.spec-main-cat {
  font-size: medium;
  background: #e9ecef;
  padding: 5px;
}

.variant_picker {
  width: 70%;
}

.product-tag-area {
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.add-item-css {
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
}

.choose-rp{
  background-color: #ff7842;
  top: auto;
  font-size: 15px;
  padding: 30px;
  color: white;
  font-style: initial;
}

.paddons-btn-3 {
  top: 10px;
  left: 132px;
  font-size: 13px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 2px 27px;
  background-color: #9d9e9e;
  color: rgb(255, 255, 255);
  display: inline-block;
  border: 1px solid transparent;
  text-transform: uppercase;
  text-align: center;
}

/* Style the tab */
.tabp{
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tabp button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tabp button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tabp button.active {
  background-color: #ccc;
}