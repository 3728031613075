* {box-sizing: border-box}
body {font-family: "Lato", sans-serif;}

/* Style the tab */
.tab {
  float: left;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  width: 20%;
  height: 100%;
  overflow: hidden;

}
/* Style the buttons inside the tab */
.tab button {
  display: block;
  background-color: inherit;
  color: black;
  padding: 22px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
  font-style: inherit; 
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current "tab button" class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border-top: none;

}
form.example input[type=text] {
  padding: 5px;
  font-size: 17px;
  border: 1px solid #e4e4e4;
  float: left;
  width: 20%;
  background: #f1f1f1;
}

form.example button {
  float: lerft;
  width: 5%;
  padding: 5px;
  background: #fcbe00;
  color: white;
  font-size: 17px;
  border-left: none;
  cursor: pointer;
  border: 1px solid #e4e4e4;
}

form.example button:hover {
  background: #fcbd0096;
}

form.example::after {
  content: "";
  clear: both;
  display: table;
}
.layout{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  margin: 10px;
}
.pagination_layout{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;

}
.pagination a {
  color: black;
  float: right;
  padding: 0px 16px;
  text-decoration: none;
  transition: background-color .3s;
}

.pagination a.active {
  background-color:#fcbe00;
  ;
  color: white;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.checkout-form-list {
  margin-bottom: 30px;
}