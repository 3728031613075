@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
body, html{
    height: 100%;
    font-family: 'Poppins',sans-serif;
    font-weight: 400;
}
.Main-container{
    width: 100%;
    margin: 0 auto;
}


.container_1{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center ;
    align-items: center;
    padding: 15px;
    background-image: url('../img/Background/sinup.png');
}
