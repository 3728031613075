.maintext{
    margin: 5px;
    padding: 10px;
    font-family: 'poppins', sans-serif;
    font-size: 24px;
    color: #333333;
    line-height: 1.2;
    text-align: center;
    font-weight: 700;
    width: 100%;
    display: block;
    
}
.emailstyle{
    width: 700px;
    background: #fff;
    border-radius: 50px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 60px 100px 40px 100px;
   
}
.emailpic{
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
}
.subtext{
    font-family: sans-serif;
    font-size: 16px;
    color: #000000;
    line-height: 1.2;
    font-weight: 100;
    width: 100%;
    display: block;
    text-align: center;
}

button{
    outline: none !important;
    border: none;
    background: transparent;
}
button:hover{
    cursor: pointer;
}
.email-form-btn{
    padding: 10px;
    font-family:'poppins',sans-serif ;
    font-size: 15px;
    line-height: 1.5;
    color: #fff;
    background: #2ab8ea;
    text-transform: uppercase;
    width: 40%;
    height: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px ;
    transition: all 0.4s;
    border: none;
    margin-left: auto;
    margin-right: auto;
}
.email-form-btn:hover{
    background: #3890af;
}
.email-form-btn-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
}