ul, .myUL
{
    list-style-type: none;
}
 
.myUL
{
    margin: 0;
    padding: 0;
}
 
.box
{
    cursor: pointer;
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
    font-size: 15px;
    font-weight: 500;
}
 
.box::before
{
    content: "+";
    color: black;
    display: inline-block;
    margin-right: 6px;
}
 
.check-box::before
{
    content: "-";
}
 
.nested
{
    display: none;
    margin: 5px;
    padding: 5px;
    font-size: 15px;
    font-weight: 300;
}
 
.active
{
    display: block;
}