.search_arrow_box {
        width: 100%;
        height: 50px;
        line-height: 50px;
        border-radius: 4px;
        border: 2px solid #ffffff;
        outline: none;
        padding: 0 15px;
        padding-left: 170px;
        padding-right: 60px;
    }
 #wrapTooltipSearch {
    position: relative;
    width: 200px;
 }
#abortTooltipSearch {
    background: #406b98;
    color: #FFF;
    border-radius: 10px;
    position: absolute;
    top: -5px;
    right: -17px;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    display: none;
}
#tooltipSearch {
    border: 1px solid #222;
    border-radius: 5px;
    font-size: 1.2em;
    outline: none;
    display: block;
    
}
#tooltipSearchSuggestions {
    border: 1px solid #222;
    position: absolute;
    max-height: 200px;
    overflow: auto;
    background: #FFF;
    padding: 5px 0px;
    width: 100%;
    display: none;
    box-shadow: 5px 5px 8px #000;
    border-radius: 10px;
}
#tooltipSearchSuggestions a {
    text-decoration: none;
    display: block;
    padding: 1px 5px;
    cursor: pointer;
}
#tooltipSearchSuggestions a:hover {
    background: #dde6ef;
    color: #000;}
#tooltipSearchSuggestions em {
    display: block;
    padding: 10px;
} 
.offcanvas__search input {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid #e5e5e5;
    font-size: 14px;
}
.offcanvas__search button {
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 14px;
    color: #999;
}